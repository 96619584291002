<template>
  <div>
    <cta-banner :title="$route.name"/>
    <div class="bg-white mb-10 mt-5" v-if="precongreso.length > 0 || congreso.length > 0">
      <nav class="flex flex-col sm:flex-row justify-center">
        <button @click="changeTaller('Pre-Congreso')" v-if="precongreso.length > 0"
          :class="typeTaller === 'Pre-Congreso' ? 'text-theme-color border-b-2 font-medium border-theme-color' : null"
          class=" py-4 px-6 block hover:text-theme-color focus:outline-none">
          Pre-congreso
        </button>
        <button @click="changeTaller('Congreso')" v-if="congreso.length > 0"
          :class="typeTaller === 'Congreso' ? 'text-theme-color border-b-2 font-medium border-theme-color' : null"
          class=" py-4 px-6 block hover:text-theme-color focus:outline-none">
          Congreso
        </button>
      </nav>
    </div>
    <div class="body-font" v-if="precongreso.length > 0 || congreso.length > 0">
      <div class="container px-5 pb-24 pt-10 mx-auto">
        <div class="flex flex-wrap -mx-4 -mb-10 text-center">
          <div class="sm:w-1/2 mb-10 px-4" v-for="taller in talleres" :key="`taller-${taller.id}`">
            <div class="rounded-lg h-80 overflow-hidden">
              <iframe :src="`https://www.youtube.com/embed/${taller.video}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" loading="lazy" class="w-full h-full"></iframe>
            </div>
            <h2 class="title text-2xl font-medium mt-6 mb-1 tracking-tight relative">{{taller.nombre}}</h2>
            <span class="inline-block h-1 w-10 rounded bg-theme-color my-1"></span>
            <p class="leading-relaxed">{{taller.descripcion}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import CtaBanner from '../../components/common/CtaBanner.vue';
import {mapState} from "vuex";

export default {
  name: "Talleres",
  components: {
    CtaBanner
  },
  data() {
    return {
      talleres: [],
      precongreso: [],
      congreso: [],
      typeTaller: 'Pre-Congreso'
    }
  },
  created () {
    this.getTalleres('Congreso');
    this.getTalleres('Pre-Congreso');
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  methods: {
    changeTaller(taller) {
      this.typeTaller = taller;
      this.talleres = (this.typeTaller === 'Congreso') ? this.congreso : this.precongreso;
    },
    async getTalleres(tipo) {
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json",
        tipo: tipo
      };
      const { data } = await API.getTalleres(params);
      if (tipo === 'Congreso'){
        this.congreso = data.results;
      } else{
        this.precongreso = data.results;
      }
      this.validate()
    },
    validate() {
      this.talleres = (this.precongreso.length > 0) ? this.precongreso : this.congreso;
    }
  },
}
</script>
